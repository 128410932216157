import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Contact from '../components/Contact';
import Card from '../components/shared/Card';
import FeaturedProjects from '../components/FeaturedProjects';
import KGOutline from '../assets/kg-outline';
import { Position } from '../types';
import type {
  Address,
  Category,
  ImageWithAlt,
  Slug,
  SanityImageObject,
} from '../types';

interface Props {
  data: {
    homePage: {
      title: string;
      _rawDescription: unknown;
      mainImage: SanityImageObject;
      featuredProjects: Array<{
        _id: string;
        title: string;
        slug: Slug;
        ongoing: boolean;
        category: Category;
        image: ImageWithAlt;
      }>;
      address: Address;
      email: string;
      phoneNumber: string;
    };
    services: {
      edges: Array<{
        node: {
          _id: string;
          title: string;
          slug: Slug;
          image: ImageWithAlt;
        };
      }>;
    };
  };
}

const serializers = {
  marks: {
    strong: ({ children }) => <span className="font-medium">{children}</span>,
    link: ({ children, mark }) => (
      <a className="cursor-pointer hover:underline" href={mark.href}>
        {children}
      </a>
    ),
    highlight: ({ children }) => (
      <span className="font-medium text-blue-500">{children}</span>
    ),
  },
};

const IndexPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout isIndexPage>
      <SEO image={data.homePage.mainImage} />

      <div className="space-y-16 sm:space-y-28">
        {/* Services */}
        <section className="relative overflow-x-hidden" id="djelatnosti">
          <div className="absolute inset-0 -z-[10] h-1/5 w-screen bg-blue-800 sm:h-1/2" />

          <h1 className="sr-only">Keso Gradnja</h1>

          <KGOutline className="w-screen pt-2 opacity-80 sm:opacity-40 md:opacity-30" />

          <div className="relative z-10 mx-auto -mt-[7vw] grid gap-10 p-4 xl:container max-sm:max-w-lg sm:grid-cols-3 sm:gap-x-8">
            {data.services.edges.map(({ node }) => (
              <Card
                href={node.slug.current}
                image={node.image}
                key={node._id}
                position={Position.portrait}
                title={<h2>{node.title}</h2>}
              />
            ))}
          </div>
        </section>

        {/* Description */}
        {data.homePage._rawDescription && (
          <section className="container" id="o-nama">
            <BlockContent
              blocks={data.homePage._rawDescription}
              className="lead-indent mx-auto max-w-prose text-xl !leading-normal text-blue-900 sm:text-2xl lg:text-3xl"
              renderContainerOnSingleChild
              serializers={serializers}
            />
          </section>
        )}

        <FeaturedProjects projects={data.homePage.featuredProjects} />

        <Contact
          address={data.homePage.address}
          email={data.homePage.email}
          phoneNumber={data.homePage.phoneNumber}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    homePage: sanityHomePage {
      _rawDescription
      mainImage {
        asset {
          _id
        }
      }
      featuredProjects {
        _id
        title
        slug {
          current
        }
        ongoing
        category {
          name
        }
        image {
          alt
          asset {
            _id
            fluid {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
      address {
        line1
        line2
      }
      email
      phoneNumber
    }
    services: allSanityService(sort: { fields: _createdAt }) {
      edges {
        node {
          _id
          title
          slug {
            current
          }
          image {
            alt
            asset {
              fluid {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
